<template>
  <div>
    <div class="infotDetai">
      <div class="menu">
        <mt-navbar v-model="selected">
          <mt-tab-item id="web_register_intro"><p @click="register('web_register_intro')">入住流程介绍</p></mt-tab-item>
          <mt-tab-item id="web_register_readme"><p @click="register('web_register_readme')">入驻须知</p></mt-tab-item>
          <mt-tab-item id="web_register_qa"><p @click="register('web_register_qa')">注册问题Q&A</p></mt-tab-item>
        </mt-navbar>
      </div>
      <mt-tab-container v-model="selected">
        <mt-tab-container-item id="web_register_intro">
          <div class="content">
            <div v-html="dataObj.content"/>
          </div>
        </mt-tab-container-item>
        <mt-tab-container-item id="web_register_readme">
          <div class="content">
            <div v-html="dataObj.content"/>
          </div>
        </mt-tab-container-item>
        <mt-tab-container-item id="web_register_qa">
          <div class="content">
            <div v-html="dataObj.content"/>
          </div>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>
  </div>
</template>

<script>
import { template } from '@/api/public'

export default {
  data () {
    return {
      selected: 'web_register_intro',
      dataObj: {}
    }
  },
  created () {
    this.register('web_register_intro')
  },
  methods: {
    register (obj) {
      template(obj).then(res => {
        console.log(res)
        this.dataObj = res.result
      }).catch(err => {
        this.requestFailed(err)
      })
    },
    // 错误请求
    requestFailed (err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    }
  }
}
</script>

<style lang="less" scoped>
.infotDetai{
    margin-top: 10px;
    padding: 0 15px;
    .title{
        color: #333;
        font-size:16px;
        margin-bottom:20px;
        text-align: center;
        margin-top:20px;
    }
    .content{
        margin-bottom: 28px;
    }
}
</style>
