var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "infotDetai" },
      [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c(
              "mt-navbar",
              {
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              },
              [
                _c("mt-tab-item", { attrs: { id: "web_register_intro" } }, [
                  _c(
                    "p",
                    {
                      on: {
                        click: function($event) {
                          return _vm.register("web_register_intro")
                        }
                      }
                    },
                    [_vm._v("入住流程介绍")]
                  )
                ]),
                _c("mt-tab-item", { attrs: { id: "web_register_readme" } }, [
                  _c(
                    "p",
                    {
                      on: {
                        click: function($event) {
                          return _vm.register("web_register_readme")
                        }
                      }
                    },
                    [_vm._v("入驻须知")]
                  )
                ]),
                _c("mt-tab-item", { attrs: { id: "web_register_qa" } }, [
                  _c(
                    "p",
                    {
                      on: {
                        click: function($event) {
                          return _vm.register("web_register_qa")
                        }
                      }
                    },
                    [_vm._v("注册问题Q&A")]
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "mt-tab-container",
          {
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          },
          [
            _c(
              "mt-tab-container-item",
              { attrs: { id: "web_register_intro" } },
              [
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.dataObj.content) }
                  })
                ])
              ]
            ),
            _c(
              "mt-tab-container-item",
              { attrs: { id: "web_register_readme" } },
              [
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.dataObj.content) }
                  })
                ])
              ]
            ),
            _c("mt-tab-container-item", { attrs: { id: "web_register_qa" } }, [
              _c("div", { staticClass: "content" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.dataObj.content) }
                })
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }